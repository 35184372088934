import React, { useState, useEffect } from "react";

import app from "../firebase";
import {
  getFirestore,
  doc,
  setDoc,
  orderBy,
  updateDoc,
  addDoc,
  Timestamp,
  onSnapshot,
  query,
  collection,
  where,
  deleteDoc,
  getDoc,
  getDocs,
} from "firebase/firestore";

const YourInnerComponent = ({ draft, schoolId, setCurrentCourseDay, allAbsentStudentList, allEnglishClassList }) => {
  return (
    <div className="border border-primary rounded p-1 mb-3">
      <h5 className="d-flex align-items-center mb-3">
        <span className="badge bg-primary me-2">{draft.classID}</span>
        <span>
          {draft.startDate?.toDate().toLocaleTimeString("zh-TW", {
            hour: "2-digit",
            minute: "2-digit",
          })}
          {" - "}
          {draft.endDate?.toDate().toLocaleTimeString("zh-TW", {
            hour: "2-digit",
            minute: "2-digit",
          })}
        </span>
      </h5>
      <div className={`d-flex flex-wrap gap-2}`}>
        {draft?.completeStudentList
          ?.filter((student) => {
            const comingOnArray = student.comingOnObjectArray;
            const comingOnObject = comingOnArray?.find((obj) => obj.classId === draft.classChosenID);
            const curDay = draft.startDate.toDate().getDay();
            const todayText = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"][curDay];
            return comingOnObject ? comingOnObject[todayText] : true;
          })
          .map((student) => {
            const isAbsent = allAbsentStudentList.some(
              (absent) => absent.studentInfo.studentName === student.studentName && absent.absentCourses.includes("英文課")
            );

            return (
              <div
                key={draft.classChosenID + student.studentId}
                className={`border rounded-pill px-3 py-1 ${
                  isAbsent ? "border-danger bg-danger-subtle text-danger" : "border-secondary-subtle bg-light"
                }`}
              >
                <span style={{ textDecoration: isAbsent ? "line-through" : "none" }}>{student.studentName}</span>
              </div>
            );
          })}
      </div>
      {/* <div className="d-flex flex-wrap gap-2">
        {allEnglishClassList
          .find((item) => item.docId == draft.classChosenID)
          ?.studentNames.map((student) => (
            <div key={draft.classChosenID + student} className="border border-secondary-subtle rounded-pill px-3 py-1 bg-light">
              {student}
            </div>
          ))}
      </div> */}
    </div>
  );
};

const DayColumn = ({
  day,
  item,
  index,
  thisDate,
  draftsInThisDay,
  setCurrentPastDate,
  setCurrentCourseDay,
  schoolId,
  DOneToTwentyFour,
  allEnglishClassList,
  allAbsentStudentList,
}) => {
  return (
    <div className="mx-2">
      <div className="inner-container d-flex">
        {index === 0 && (
          <button
            onClick={() => {
              setCurrentPastDate((prev) => {
                var newDate = new Date(prev);
                newDate.setDate(newDate.getDate() - 7);
                return newDate;
              });
            }}
            className="button"
          >
            {`<`}
          </button>
        )}
        <h3 className="date-text">
          {item} {thisDate.toLocaleDateString("zh-TW", { month: "short", day: "numeric", weekday: "short" })}
        </h3>
        {index === 5 && (
          <button
            onClick={() => {
              setCurrentPastDate((prev) => {
                var newDate = new Date(prev);
                newDate.setDate(newDate.getDate() + 7);
                return newDate;
              });
            }}
            className="button"
          >
            {`>`}
          </button>
        )}
      </div>

      {allAbsentStudentList.filter((item) => item.leaveDate.toDate().toDateString() === thisDate.toDateString()).length > 0 && (
        <div className="alert alert-danger py-2" role="alert">
          <div className="d-flex flex-wrap gap-1 align-items-center">
            <strong>
              請假學生({allAbsentStudentList.filter((item) => item.leaveDate.toDate().toDateString() === thisDate.toDateString()).length}):
            </strong>
            {allAbsentStudentList
              .filter((item) => item.leaveDate.toDate().toDateString() === thisDate.toDateString())
              .map((item) => (
                <span key={item.docId} className="badge bg-danger-subtle text-danger">
                  {item.studentInfo.studentName}
                  <small className="ms-0 opacity-75">({item.absentCourses.join(", ")})</small>
                </span>
              ))}
          </div>
        </div>
      )}

      <div>
        {draftsInThisDay.map((draft, index) => (
          <YourInnerComponent
            draft={draft}
            schoolId={schoolId}
            setCurrentCourseDay={setCurrentCourseDay}
            DOneToTwentyFour={DOneToTwentyFour}
            allEnglishClassList={allEnglishClassList}
            allAbsentStudentList={allAbsentStudentList}
          />
        ))}
      </div>
    </div>
  );
};

const CheckAttendance = ({ schoolId }) => {
  const db = getFirestore(app);

  const [diaryDraftList, setDiaryDraftList] = useState([]);
  const [currentCourseDay, setCurrentCourseDay] = useState("");

  const [DOneToTwentyFour, setDOneToTwentyFour] = useState([]);
  var myCurrentDate = new Date();
  const [currentPastDate, setCurrentPastDate] = useState(new Date());

  const [allEnglishClassList, setAllEnglishClassList] = useState([]);

  const [allAbsentStudentList, setAllAbsentStudentList] = useState([]);

  const getAllStudentList = async () => {
    let q = query(collection(db, `schools/${schoolId}/schoolClasses`), where("status", "==", "開班ing"));
    let querySnapshot = await getDocs(q);
    let temp = [];
    querySnapshot.forEach((doc) => {
      if (doc.exists) {
        temp.push({ docId: doc.id, ...doc.data() });
      }
    });
    setAllEnglishClassList(temp);
  };

  const fetchAllAbsentStudnetList = async () => {
    let pastDate = currentPastDate;
    let deductable = pastDate.getDay() - 1;
    if (pastDate.getDay == 0) {
      deductable = 7;
    }
    pastDate.setDate(pastDate.getDate() - deductable);
    pastDate.setHours(0, 0, 0, 0);

    let futureDate = new Date(pastDate);
    futureDate.setDate(futureDate.getDate() + 7);

    let q = query(
      collection(db, `absentStudentRecord`),
      where("schoolId", "==", schoolId),
      where("leaveDate", ">=", pastDate),
      where("leaveDate", "<", futureDate)
    );
    let querySnapshot = await getDocs(q);
    let temp = [];
    querySnapshot.forEach((doc) => {
      if (doc.exists) {
        temp.push({ docId: doc.id, ...doc.data() });
      }
    });
    setAllAbsentStudentList(temp);
    console.log(temp);
  };

  const getDraftDiaryList = async () => {
    // var pastDate = getPastDate();
    var pastDate = currentPastDate;
    var deductable = pastDate.getDay() - 1;
    if (pastDate.getDay == 0) {
      deductable = 7;
    }
    pastDate.setDate(pastDate.getDate() - deductable);
    pastDate.setHours(0, 0, 0, 0);

    var futureDate = new Date(pastDate);
    futureDate.setDate(futureDate.getDate() + 7);

    let q = query(
      collection(db, "bigDraftDiaryList"),
      where("schoolId", "==", schoolId),
      where("startDate", ">=", pastDate),
      where("startDate", "<", futureDate),
      orderBy("startDate", "asc")
    );

    let querySnapshot = await getDocs(q);
    let temp = [];
    querySnapshot.forEach((doc) => {
      if (doc.exists) {
        temp.push({ docId: doc.id, ...doc.data() });
      }
    });
    setDiaryDraftList(temp);
  };

  const fetchDOneToTwentyFour = async () => {
    let q = query(collection(db, "DOneToTwentyFourCollection"));
    let querySnapshot = await getDocs(q);
    let temp = [];
    querySnapshot.forEach((doc) => {
      if (doc.exists) {
        temp.push({ docId: doc.id, ...doc.data() });
      }
    });
    setDOneToTwentyFour(temp);
  };

  useEffect(() => {
    getDraftDiaryList();
    fetchDOneToTwentyFour();
    getAllStudentList();
    fetchAllAbsentStudnetList();
  }, [currentPastDate]);

  return (
    <div style={{ display: "flex" }}>
      {[
        { day: "Mon", items: ["Item 1", "Item 2", "Item 3"] },
        { day: "Tue", items: ["Item 4", "Item 5", "Item 6"] },
        { day: "Wed", items: ["Item 7", "Item 8", "Item 9"] },
        { day: "Thu", items: ["Item 10", "Item 11", "Item 12"] },
        { day: "Fri", items: ["Item 13", "Item 14", "Item 15"] },
        { day: "Sat", items: ["Item 16", "Item 17", "Item 18"] },
      ].map((dayData, index) => {
        let draftsInThisDay = diaryDraftList.filter((draft) => {
          return draft.startDate?.toDate().getDay() == index + 1;
        });

        let thisDate = new Date(currentPastDate);
        thisDate.setDate(thisDate.getDate() + index);

        return (
          <DayColumn
            key={index}
            day={dayData.day}
            draftsInThisDay={draftsInThisDay}
            setCurrentPastDate={setCurrentPastDate}
            item={dayData.day}
            index={index}
            thisDate={thisDate}
            setCurrentCourseDay={setCurrentCourseDay}
            DOneToTwentyFour={DOneToTwentyFour}
            allEnglishClassList={allEnglishClassList}
            allAbsentStudentList={allAbsentStudentList}
          />
        );
      })}
    </div>
  );
};

export default CheckAttendance;
