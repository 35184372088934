import React, { useState, useEffect } from "react";
import app from "../firebase";
import { getFirestore, query, collection, where, getDocs, orderBy } from "firebase/firestore";

const OneDayInnerComponent = ({ draft, allEnglishClassList, allAbsentStudentList, selectedDate, isImageMode }) => {
  return (
    <div className="border border-primary rounded p-2 mb-2 shadow-sm">
      <h5 className="d-flex align-items-center mb-3">
        <span className="badge bg-primary me-2">{draft.classID}</span>
        <span>
          {draft.startDate?.toDate().toLocaleTimeString("zh-TW", {
            hour: "2-digit",
            minute: "2-digit",
          })}{" "}
          -{" "}
          {draft.endDate?.toDate().toLocaleTimeString("zh-TW", {
            hour: "2-digit",
            minute: "2-digit",
          })}
        </span>
      </h5>

      <div className={`d-flex flex-wrap ${!isImageMode && "gap-2"}`}>
        {draft?.completeStudentList
          ?.filter((student) => {
            const comingOnArray = student.comingOnObjectArray;
            const comingOnObject = comingOnArray?.find((obj) => obj.classId === draft.classChosenID);
            const curDay = draft.startDate.toDate().getDay();
            const todayText = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"][curDay];
            return comingOnObject ? comingOnObject[todayText] : true;
          })
          .map((student) => {
            const isAbsent = allAbsentStudentList.some(
              (absent) => absent.studentInfo.studentName === student.studentName && absent.absentCourses.includes("英文課")
            );

            if (!isImageMode) {
              return (
                <div
                  key={draft.classChosenID + student.studentId}
                  className={`border rounded-pill px-3 py-1 ${
                    isAbsent ? "border-danger bg-danger-subtle text-danger" : "border-secondary-subtle bg-light"
                  }`}
                >
                  <span style={{ textDecoration: isAbsent ? "line-through" : "none" }}>{student.studentName}</span>
                </div>
              );
            }

            return (
              <div className="p-0 text-center" key={draft.classChosenID + student.studentId}>
                <div className="position-relative">
                  <img
                    src={student.fullAvatarImg || "https://via.placeholder.com/64"}
                    alt={student.studentName}
                    className="rounded"
                    style={{
                      width: "84px",
                      height: "84px",
                      objectFit: "cover",
                      border: isAbsent ? "2px solid #dc3545" : "2px solid #007bff",
                      opacity: isAbsent ? 0.2 : 1,
                      margin: "2px",
                    }}
                  />
                  {isAbsent && (
                    <div
                      className="position-absolute top-50 start-50 translate-middle"
                      style={{
                        color: "#dc3545",
                        fontSize: "16px",
                        fontWeight: "bold",
                        textShadow: "1px 1px 2px white",
                      }}
                    >
                      請假
                    </div>
                  )}
                </div>
                <p
                  className="fw-semibold text-nowrap"
                  style={{
                    width: "84px",
                    fontSize: student.studentName.length > 9 ? "11px" : student.studentName.length > 8 ? "13px" : "14px",
                    paddingTop: student.studentName.length > 9 ? "3px" : student.studentName.length > 8 ? "1px" : "0px",
                  }}
                >
                  {student.studentName}
                </p>
              </div>
            );
          })}
      </div>
    </div>
  );
};

const groupDraftsByRow = (drafts) => {
  const rows = [];
  let currentRow = [];

  drafts.forEach((draft, index) => {
    if (index === 0) {
      currentRow.push(draft);
    } else {
      const previousDraft = currentRow[currentRow.length - 1];
      if (draft.startDate.toDate().getTime() - previousDraft.startDate.toDate().getTime() <= 3500000) {
        currentRow.push(draft);
      } else {
        rows.push(currentRow);
        currentRow = [draft];
      }
    }
  });

  if (currentRow.length > 0) {
    rows.push(currentRow);
  }

  return rows;
};

const CheckAttendanceDay = ({ schoolId }) => {
  const db = getFirestore(app);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [diaryDraftList, setDiaryDraftList] = useState([]);
  const [allEnglishClassList, setAllEnglishClassList] = useState([]);
  const [allAbsentStudentList, setAllAbsentStudentList] = useState([]);
  const [isImageMode, setIsImageMode] = useState(true);

  const handleDateChange = (days) => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() + days);
    setSelectedDate(newDate);
  };

  const getAllStudentList = async () => {
    const q = query(collection(db, `schools/${schoolId}/schoolClasses`), where("status", "==", "開班ing"));
    const querySnapshot = await getDocs(q);
    const temp = [];
    querySnapshot.forEach((doc) => {
      if (doc.exists) {
        temp.push({ docId: doc.id, ...doc.data() });
      }
    });
    setAllEnglishClassList(temp);
  };

  const fetchAllAbsentStudnetList = async () => {
    const start = new Date(selectedDate);
    start.setHours(0, 0, 0, 0);
    const end = new Date(start);
    end.setDate(end.getDate() + 1);

    const q = query(
      collection(db, `absentStudentRecord`),
      where("schoolId", "==", schoolId),
      where("leaveDate", ">=", start),
      where("leaveDate", "<", end)
    );
    const querySnapshot = await getDocs(q);
    const temp = [];
    querySnapshot.forEach((doc) => {
      if (doc.exists) {
        temp.push({ docId: doc.id, ...doc.data() });
      }
    });
    setAllAbsentStudentList(temp);
  };

  const getDraftDiaryList = async () => {
    const start = new Date(selectedDate);
    start.setHours(0, 0, 0, 0);
    const end = new Date(start);
    end.setDate(end.getDate() + 1);

    const q = query(
      collection(db, "bigDraftDiaryList"),
      where("schoolId", "==", schoolId),
      where("startDate", ">=", start),
      where("startDate", "<", end),
      orderBy("startDate", "asc")
    );

    const querySnapshot = await getDocs(q);
    const temp = [];
    querySnapshot.forEach((doc) => {
      if (doc.exists) {
        temp.push({ docId: doc.id, ...doc.data() });
      }
    });
    console.log(temp, "diary");
    setDiaryDraftList(temp);
  };

  useEffect(() => {
    getDraftDiaryList();
    getAllStudentList();
    fetchAllAbsentStudnetList();
  }, [selectedDate]);

  return (
    <div>
      <div className="d-flex align-items-center mb-1">
        <button className="btn btn-outline-primary me-2" onClick={() => handleDateChange(-1)}>
          前一天
        </button>
        <h3 className="m-0">
          {selectedDate.toLocaleDateString("zh-TW", {
            month: "short",
            day: "numeric",
            weekday: "short",
          })}
        </h3>
        <button className="btn btn-outline-secondary ms-auto" onClick={() => setIsImageMode(!isImageMode)}>
          {isImageMode ? "切換到文字模式" : "切換到圖片模式"}
        </button>

        <button className="btn btn-outline-primary ms-2" onClick={() => handleDateChange(1)}>
          後一天
        </button>
      </div>

      {allAbsentStudentList.length > 0 && (
        <div className="alert alert-danger py-2" role="alert">
          <div className="d-flex flex-wrap gap-2 align-items-center">
            <strong>請假學生({allAbsentStudentList.length}):</strong>
            {allAbsentStudentList.map((item) => (
              <span key={item.docId} className="badge bg-danger-subtle text-danger">
                {item.studentInfo.studentName}
                <small className="ms-1 opacity-75">({item.absentCourses.join(", ")})</small>
              </span>
            ))}
          </div>
        </div>
      )}

      <div>
        {groupDraftsByRow(diaryDraftList).map((row, rowIndex) => (
          <div key={rowIndex} className="d-flex gap-1 mb-1">
            {row.map((draft) => (
              <div key={draft.docId} style={{ flex: `0 1 ${100 / row.length}%` }}>
                <OneDayInnerComponent
                  draft={draft}
                  allEnglishClassList={allEnglishClassList}
                  allAbsentStudentList={allAbsentStudentList}
                  selectedDate={selectedDate}
                  isImageMode={isImageMode}
                />
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CheckAttendanceDay;
